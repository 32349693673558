@media screen and (max-width: 991px) and (min-width: 700px) {
  .desktop-nav {
    display: none;
  }
  .mobile-tablet-nav {
    display: block;
  }
  .desktop-screen {
    display: none;
  }
  .tablet-s {
    display: block;
  }
  .no-px-tab {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .home-s-1 {
    padding-top: 100px;
  }
  .home-s1-h1 {
    font-size: 40px;
  }
  .home-s-2 h1 {
    font-size: 40px !important;
  }
  .mb-tab-30 {
    margin-bottom: 30px;
  }
  .px-tab-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .copyright-text {
    float: none !important;
    text-align: center;
  }
  .footer-link-center {
    text-align: center !important;
  }
}
@media only screen and (max-width: 991px) {
  .desktop-nav {
    display: none;
  }
  .mobile-tablet-nav {
    display: block;
  }
  .no-px-tab {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
}
@media (min-width: 365px) and (max-width: 400px) {
  .empty-space {
    display: block;
  }
  .project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
@media (min-width: 400px) and (max-width: 767px) {
  .project-image {
    width: 100%;
    height: auto;
    object-fit: cover;
  }
}
@media (min-width: 768px) and (max-width: 992px) {
  .empty-space {
    display: block;
  }
}
@media only screen and (max-width: 500px) {
  .desktop-nav {
    display: none;
  }
  .mobile-tablet-nav {
    display: block;
  }
  .desktop-screen {
    display: none;
  }
  .tablet-s {
    display: block;
  }
  .no-px-tab {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .home-s-1 {
    padding-top: 50px;
  }
  .home-s1-h1 {
    font-size: 40px;
  }
  .home-s-2 {
    padding: 40px 0px;
  }
  .home-s-2 h1 {
    font-size: 40px !important;
  }
  .mb-tab-30 {
    margin-bottom: 30px;
  }
  .px-tab-20 {
    padding-left: 20px !important;
    padding-right: 20px !important;
  }
  .mobile-text-center {
    text-align: center;
  }
  .mobile-d-none {
    display: none;
  }
  .copyright-text {
    float: none !important;
  }
  .footer-content {
    padding-bottom: 20px !important;
  }
  .main-breadcrum-section .container-fluid .container .row {
    padding-top: 0px !important;
  }
  .h-s3-h1 {
    font-size: 30px;
  }
}
