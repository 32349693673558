@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Barlow", sans-serif;
}
.mobile-s {
  display: none;
}
.tablet-s {
  display: none;
}
.tablet-nav {
  display: none;
}
.color-light-blue {
  color: #4b87be;
  text-decoration: none;
}
.color-dark-blue {
  color: #204d86;
  text-decoration: none;
}
.color-yellow {
  color: #ffb703;
  text-decoration: none;
}
.color-black {
  color: #011c27;
  text-decoration: none;
}
.bg-light-blue {
  background-color: #4b87be !important;
}
.bg-dark-blue {
  background-color: #204d86 !important;
}
.bg-yellow {
  background-color: #ffb703;
}
.bg-black1 {
  background-color: #011c27;
}
.fw-100 {
  font-weight: 100 !important;
}
.fw-200 {
  font-weight: 200 !important;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}
.fs-50 {
  font-size: 50px !important;
}
.fs-45 {
  font-size: 45px !important;
}
.fs-40 {
  font-size: 40px !important;
}
.fs-35 {
  font-size: 35px !important;
}
.fs-30 {
  font-size: 30px !important;
}
.fs-25 {
  font-size: 25px !important;
}
.fs-24 {
  font-size: 24px !important;
}
.fs-23 {
  font-size: 23px !important;
}
.fs-22 {
  font-size: 22px !important;
}
.fs-21 {
  font-size: 21px !important;
}
.fs-20 {
  font-size: 20px !important;
}
.fs-19 {
  font-size: 19px !important;
}
.fs-18 {
  font-size: 18px !important;
}
.fs-17 {
  font-size: 17px !important;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-15 {
  font-size: 15px !important;
}
.fs-14 {
  font-size: 14px !important;
}
.fs-13 {
  font-size: 13px !important;
}
.fs-12 {
  font-size: 12px !important;
}
.fs-11 {
  font-size: 11px !important;
}

ul.navbar-nav .nav-item {
  padding: 10px 0px;
}
.nav-item .nav-link {
  color: #011c27 !important;
  font-weight: 600 !important;
  padding-left: 15px !important;
  padding-right: 15px !important;
}
.nav-item .pr-30 {
  padding-right: 30px !important;
}
.banner-bg {
  background-image: url(../assets/images/banner.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.home-s-1 {
  padding-top: 150px;
}
.home-s1-h1 {
  font-size: 60px;
}
.home-s-2 {
  background-color: #f7f7f7;
  padding: 70px 0px;
}
.home-s-3 {
  background-color: #011c27;
  padding: 70px 0px;
}
.h-s3-h1 {
  font-size: 50px;
}
.home-s-4 {
  background-color: #f7f7f7;
  padding: 70px 0px;
}
.h-s4-card {
  background-color: #fff;
}
.h-s4-card .icon-div {
  padding: 5px;
  background-color: #f2f6f8;
  float: right;
}
.h-s4-card .h4-card-body {
  padding: 30px 20px 0px 20px;
}
.h-s4-card .h4-card-body span.number {
  font-size: 56px;
  font-weight: 600;
}
.h-s4-card .h4-card-body p {
  line-height: 24px;
  font-weight: 600;
}
.yellow-btn {
  background-color: #ffb703;
  border: 1px solid #ffb703;
  padding: 10px 20px;
  font-weight: 600;
}
.home-s-5 {
  padding: 70px 0px;
}
.home-s-6 {
  background-color: #f7f7f7;
  padding: 70px 0px;
}

.footer-section {
  background: #011c27;
  position: relative;
}
.footer-content {
  border-bottom: 1px solid #f7f7f7;
}
.single-cta i {
  color: #ff5e14;
  font-size: 30px;
  float: left;
  margin-top: 8px;
}
.cta-text {
  padding-left: 15px;
  display: inline-block;
}
.cta-text h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 2px;
}
.cta-text span {
  color: #757575;
  font-size: 15px;
}
.footer-content {
  position: relative;
  z-index: 2;
}
.footer-pattern img {
  position: absolute;
  top: 0;
  left: 0;
  height: 330px;
  background-size: cover;
  background-position: 100% 100%;
}
.footer-logo {
  margin-bottom: 30px;
}
.footer-logo img {
  max-width: 200px;
}
.footer-text p {
  margin-bottom: 14px;
  font-size: 14px;
  color: #7e7e7e;
  line-height: 28px;
}
.footer-social-icon span {
  color: #fff;
  display: block;
  font-size: 20px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  margin-bottom: 20px;
}
.footer-social-icon a {
  color: #fff;
  font-size: 16px;
  margin-right: 15px;
}
.footer-social-icon i {
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 38px;
  border-radius: 50%;
}
.facebook-bg {
  background: #3b5998;
}
.twitter-bg {
  background: #55acee;
}
.google-bg {
  background: #dd4b39;
}
.footer-widget-heading h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  position: relative;
}
.footer-widget-heading h3::before {
}
.footer-widget ul li {
  display: inline-block;
  float: left;
  width: 50%;
  margin-bottom: 12px;
}
.footer-widget ul li a:hover {
  color: #ff5e14;
}
.footer-widget ul li a {
  color: #878787;
  text-transform: capitalize;
}
.subscribe-form {
  position: relative;
  overflow: hidden;
}
.subscribe-form input {
  width: 100%;
  padding: 14px 28px;
  background: #2e2e2e;
  border: 1px solid #2e2e2e;
  color: #fff;
}
.subscribe-form button {
  position: absolute;
  right: 0;
  background: #ff5e14;
  padding: 13px 20px;
  border: 1px solid #ff5e14;
  top: 0;
}
.subscribe-form button i {
  color: #fff;
  font-size: 22px;
  transform: rotate(-6deg);
}
.copyright-area {
  background: #011c27;
  padding: 25px 0;
}
.copyright-text {
  float: right !important;
}
.copyright-text p {
  margin: 0;
  font-size: 14px;
  color: #878787;
}
.copyright-text p a {
  color: #ffffff;
}
.footer-menu li {
  display: inline-block;
  margin-left: 20px;
}
.footer-menu li:hover a {
  color: #ff5e14;
}
.footer-menu li a {
  font-size: 14px;
  color: #878787;
}
.footer-ul li a.footer-links {
  color: #fff;
  text-decoration: none;
}
.about-s-2 {
  background-color: #f7f7f7;
  padding: 70px 0px;
}
.about-s-3 {
  background-image: url(../assets/images/about-s3.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
}
.about-s-3 .container-fluid {
  padding: 70px 0px;
  background-color: rgba(0, 0, 0, 0.4);
}

.about-s4-card {
  background-color: #f7f7f7;
}
.about-s4-card .about-h4-card-body {
  padding: 20px 20px 20px 20px;
}
.about-s4-card .about-h4-card-body span.number {
  font-size: 56px;
  font-weight: 600;
}
/*.about-s4-card .about-h4-card-body p{
	line-height: 24px;
	font-weight: 600;
}*/
.cpb-s-3 {
  padding: 70px 0px;
}
.news-s-2 {
  padding: 70px 0px;
  background-color: #f7f7f7;
}
.news-detail-s-3 {
  background-color: #f7f7f7;
  padding: 70px 0px;
}
.projects-s-3 {
  padding: 0px 0px 70px 0px;
}
.project-tab-nav .nav-item .nav-link {
  color: #fff !important;
}
.project-tab-nav .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: none !important;
  /* border: none; */
  color: #4b87be !important;
}
.project-tab-nav .nav-item .nav-link:hover {
  border: none !important;
  color: #4b87be !important;
}

.nav-tabs {
  border: none !important;
}
.project-details-bg {
  background-image: url("../assets/images/banner-bg.png") !important;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.cookie-disclaimer {
  background: #fff;
  color: #000;
  opacity: 1;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 1;
  /*height: 50px;*/
  position: fixed;
}
.cookie-disclaimer .container {
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
}

.cookie-disclaimer .cookie-close {
  float: right;
  padding: 10px;
  cursor: pointer;
}
.accept-cookies-btn {
  border: 1px solid #000;
  background-color: #fff;
  padding: 15px 30px;
  font-weight: 600;
}
.accept-cookies-btn:hover {
  background-color: #000;
  color: #fff;
}
.mobile-tablet-nav {
  display: none;
}
.tablet-s {
  display: none;
}

.btnNew:focus {
  border: none !important;
}

.btnNew:focus-visible {
  outline: none;
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border: none !important;
}

.nav-tabs .nav-link {
  border: none !important;
  border-top-left-radius: none !important;
  border-top-right-radius: none !important;
}

.showHide {
  display: none;
  transition: all 1s ease-out;
}

.project-dropdown {
  display: none;
  position: absolute;
  min-width: 125px;
  z-index: 1;
  left: 0;
  top: 40px;
}
.project-dropdown-inner {
  display: none;
  position: absolute;
  min-width: 220px;
  z-index: 1;
  left: 120px;
  top: 80px;
}

.project-dropdown a {
  color: #011c27;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
.project-dropdown-inner a {
  color: #011c27;
  padding: 2px 16px;
  text-decoration: none;
  display: block;
  cursor: pointer;
}

.p-dropdown:hover .project-dropdown {
  display: block;
}
.p-dropdown-inner:hover .project-dropdown-inner {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
}

.empty-space {
  display: none;
}

.owl-carousel .owl-item img {
  object-fit: cover !important;
}
